<template>
  <div class="list">
    <div class="article_form">
      <div class="article_form_title">个人信息</div>
      <div class="list_line_cont">
          <div class="list_line"><div class="list_line_a">用户账号：</div>{{User.userName}}</div>
          <div class="list_line"><div class="list_line_a">注册日期：</div>{{getDate(User.zhuceriqi)}}</div>
          <div class="list_line"><div class="list_line_a import">姓名：</div><input type="text" v-model="User.xingming"></div>
          <div class="list_line"><div class="list_line_a">手机号码：</div>{{User.phone}}<button type="button" @click="phoneShow=true">修改</button></div>
          <div class="list_line"><div class="list_line_a">密码：</div>***********<button type="button" @click="passShow=true">修改</button></div>
          <div class="list_line"><div class="list_line_a">所属门店：</div>
            <select @change="addStoreName" v-model="User.fenpeimendianId" :disabled="User.jibieId!=0">
              <option :value="item.id" v-for="item in storeList" :key="item.id">{{item.storeName}}</option>
            </select>
            <small v-if="User.jibieId==0" style="color:#aaa"> 主页切换门店可立即生效</small>
          </div>
          <div class="list_line"><div class="list_line_a">级别：</div>{{User.jibieId==0?'主账户':User.jibieName}}</div>
          <div class="list_line"><div class="list_line_a">性别：</div><label><input type="radio" value="女" v-model="User.xingbie">女</label><label><input type="radio" value="男" v-model="User.xingbie">男</label></div>
          <div class="list_line"><div class="list_line_a">生日：</div><input type="date" v-model="User.shengri"></div>
          <div class="list_line"><div class="list_line_a">用户邮箱：</div><input type="text" v-model="User.dianziyouxiang"></div>
          <div class="list_line"><div class="list_line_a">推荐码：</div>{{13236520+User.id}}</div>
          <div class="list_line"><div class="list_line_a">推荐积分：</div>{{User.jifen}}<a href="javascript:void(0)" @click="goUrl('/exchange','积分兑换')">积分兑换</a></div>
      </div>
      <div class="center_b_4">
        <button class="settab" @click="subData">修改</button>
        <button class="remole" style="margin-left:50px" @click="closedWindow">返回</button>
      </div>
    </div>
    <div class="meg_all" v-if="passShow">
      <div class="meg smallMeg" style="height:300px">
        <h1>修改密码</h1>
         <div class="article_form_li">
          <ul>
            <li><div class="left_title">原密码：</div><input type="password" v-model="passWord.old"></li>
            <li><div class="left_title">新密码：</div><input type="password" v-model="passWord.newOne"></li>
            <li><div class="left_title">重复新密码：</div><input type="password" v-model="passWord.newAgin"></li>
          </ul>
         </div>
        <div class="meg_c"><button type="button" class="off" @click="closdMeg()">取消</button><button type="button" class="sub" @click="emitPass">确定</button></div>
        <div class="meg_cld" @click="closdMeg()"><i class="icon-x"></i></div>
      </div>
    </div>
    <div class="meg_all" v-if="phoneShow">
      <div class="meg smallMeg" style="height:300px">
        <h1>修改手机号码</h1>
         <div class="article_form_li">
          <ul>
            <li><div class="left_title">密码：</div><input type="password" v-model="phone.passWord"></li>
            <div class="jiange_10"></div>
            <li><div class="left_title">新手机号码：</div><input type="text" v-model="phone.phone" maxlength="11"></li>
          </ul>
         </div>
        <div class="meg_c"><button type="button" class="off" @click="closdMeg()">取消</button><button type="button" class="sub" @click="emitPhone">确定</button></div>
        <div class="meg_cld" @click="closdMeg()"><i class="icon-x"></i></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/http/axios'
import {testStr} from '@/utils/util'
// import {zffsList} from '@/data/data'
import {mapMutations } from "vuex"
import moment from 'moment'
export default {
  name: 'perPro',
  data() {
    return {
      form:{
        fenpeimendianId:this.$store.state.User.fenpeimendianId,
      },
      passShow:false,
      phoneShow:false,
      passWord:{
        old:'',
        newOne:'',
        newAgin:''
      },
      phone:{
        passWord:'',
        phone:'',
      },
      User:{},
      storeList:[],
    }
  },
  created() {
    this.getStore()
    this.getMeg()
  },
  components: {},
  mounted() {},
  methods: {
    ...mapMutations(['clearUser','addNav']),
    emitPhone(){
      if(this.User.passWord==this.phone.passWord){
        if(this.phone.phone.length==11){
            axios.post('/editPhone',{id:this.User.id,...this.phone}).then((res) => {
              if(res.code==5000){
                this.$Mesg({content:"修改手机号码成功！"})
                this.getMeg()
                this.closdMeg()
              }else this.$Mesg({content: res.msg,errOrSuc: 'err'})
            })
        }else{
          this.$Mesg({content:"请输入正确的手机号码！",errOrSuc: 'err'})
        }
      }else{
        this.$Mesg({content:"密码错误！",errOrSuc: 'err'})
      }
    },
    emitPass(){
      if(this.User.passWord==this.passWord.old){
        if(this.passWord.newOne.length>=6&&this.passWord.newOne.length<=20){
          if(this.passWord.newOne==this.passWord.newAgin){
            axios.post('/editPass',{id:this.User.id,...this.passWord}).then((res) => {
              if(res.code==5000){
                this.$Mesg({content:"修改密码成功，请重新登录！"})
                this.clearUser()
                sessionStorage.removeItem("dongduUser")
                this.$router.push('/')
              }else this.$Mesg({content: res.msg,errOrSuc: 'err'})
            })
          }else{
            this.$Mesg({content:"两次输入的密码不一致！",errOrSuc: 'err'})
          }
        }else{
          this.$Mesg({content:"新密码长度应于6-20位之间！",errOrSuc: 'err'})
        }
      }else{
        this.$Mesg({content:"原密码错误！",errOrSuc: 'err'})
      }
    },
    closdMeg(){
      this.passShow=false
      this.phoneShow=false
    },
    addStoreName(){
      let store=this.storeList.find(e=>e.id==this.User.fenpeimendianId)
      if(store) this.User.fenpeimendianName=store.storeName
    },
    getDateTime(sr){
      if(sr) return moment(sr).format("YYYY-MM-DD HH:mm:ss")
      else return ''
    },
    getDate(sr){
      if(sr) return moment(sr).format("YYYY-MM-DD")
      else return ''
    },
    subData(){
      if(testStr(this.User.xingming)){
        this.$Mesg({
          content: '姓名不能为空！',
          errOrSuc: 'war',
        })
        return
      }
      if(this.User.shengri) this.User.shengri=this.getDate(this.User.shengri)
      axios.post('/editUser',this.User).then((res) => {
        if(res.code==5000){
          this.$Mesg({content:"修改信息成功，请重新登录！"})
          this.clearUser()
          sessionStorage.removeItem("dongduUser")
          this.$router.push('/')
        }else this.$Mesg({content: res.msg,errOrSuc: 'err'})
      })
    },
    closedWindow(){
      this.$router.back()
    },
    getMeg(){
      axios.post('/getInfoById').then((res) => {
        if(res.code===5000){
          this.User=res.data
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    getStore(){
      axios.post('/store/list').then((res) => {
        if(res.code===5000){
          this.storeList=res.data
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    goUrl(url,name){
      let pa={
        name,
        path:url,
      }
      this.addNav(pa)
      this.$router.push(url)
    }
  },
}
</script>
